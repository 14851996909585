<script setup lang="ts">
import { defineEmits, defineProps } from 'vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import TextareaInput from '@/vueComponents/photographer/molecules/TextareaInput/index.vue'
import {
  DeliverySettingDialogEmitParams,
  IDeliverySettingDialogProps,
  useDeliverySettingDialogStates,
} from '.'

interface IProps {
  sellerName: IDeliverySettingDialogProps['sellerName']
  eventName: IDeliverySettingDialogProps['eventName']
  value: IDeliverySettingDialogProps['value']
}

interface IEmits {
  (
    e: 'update:value',
    value: DeliverySettingDialogEmitParams['update:value']
  ): void
}

const props = defineProps<IProps>()

const emits = defineEmits<IEmits>()

const states = useDeliverySettingDialogStates(props, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/space' as space-mixins;
.lead {
  @include space-mixins.vertical-margin(8);
}
.comfirmationText {
  @include space-mixins.vertical-margin(4);
}
.textareaInput {
  @include space-mixins.vertical-margin(6, 8);
}
</style>

<template>
  <Typography element="p" type="h3" :class="css.lead">
    「{{ props.sellerName }}
    {{ props.eventName }}」の内容をご確認の上、検品依頼をしてください。
  </Typography>
  <Typography element="p" :class="css.comfirmationText"
    >検品依頼後はイベントの編集作業（追加・削除）ができません。<br />
    本当に検品依頼をしてよろしいですか？<br />
    ※すべての写真をアップロードしてから、検品依頼をしてください。
  </Typography>

  <TextareaInput
    v-model:value="states.value"
    label="納品物に対しての説明をご記入ください。"
    :is-must="true"
    name="delivery-memo"
    :maxlength="500"
    placeholder="納品内容について共有事項がございましたらご記入ください(共有事項がない場合には「特になし」とご記入ください)"
    :class="css.textareaInput"
  />
</template>
