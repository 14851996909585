<script setup lang="ts">
import { defineEmits, defineProps } from 'vue'
import Typography from '@/vueComponents/photographer/atoms/Typography/index.vue'
import Textarea from '@/vueComponents/photographer/atoms/Textarea/index.vue'
import InputError from '@/vueComponents/photographer/atoms/InputError/index.vue'
import MustLabel from '@/vueComponents/photographer/atoms/MustLabel/index.vue'
import {
  ITextareaInputProps,
  TextareaInputEmitParams,
  useTextareaInputStates,
} from '.'

interface IProps {
  label?: ITextareaInputProps['label']
  name?: ITextareaInputProps['name']
  value?: ITextareaInputProps['value']
  placeholder?: ITextareaInputProps['placeholder']
  disabled?: ITextareaInputProps['disabled']
  maxlength?: ITextareaInputProps['maxlength']
  errorMessage?: ITextareaInputProps['errorMessage']
  isMust?: ITextareaInputProps['isMust']
}

interface IEmits {
  (e: 'update:value', value: TextareaInputEmitParams['update:value']): void
}

const props = defineProps<IProps>()

const emits = defineEmits<IEmits>()

const states = useTextareaInputStates(props, emits)
</script>

<style lang="scss" module="css" scoped>
@use '@/styles/mixins/space' as space-mixins;
.label {
  vertical-align: text-bottom;
}
.textarea {
  @include space-mixins.vertical-margin(4);
}
.errorText {
  @include space-mixins.vertical-margin(4);
}
</style>

<template>
  <div>
    <label>
      <Typography v-if="props.label"
        >{{ props.label }}<MustLabel v-if="props.isMust" :class="css.label"
      /></Typography>
      <Textarea
        v-model:value="states.value"
        :name="props.name"
        :placeholder="props.placeholder"
        :disabled="props.disabled"
        :maxlength="props.maxlength"
        :class="css.textarea"
      ></Textarea>
    </label>
    <InputError v-if="!!props.errorMessage" :class="css.errorText">{{
      props.errorMessage
    }}</InputError>
  </div>
</template>
